import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { breakpoints, Collapse, Loading, useShoppingCart, useAuth } from '@ecommerce/shared'
import secrets from '../config/secrets'
import LayoutStaticPage from '../components/Layout/LayoutStaticPage'
import withPageTransition from '../components/withPageTransition'
import { PgPageProps, CollapsibleContent } from '../types/PgPages'
import { sendPageViewEvent } from '../utils/events'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey};
  min-height: calc(100vh - 231px);

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }

  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
  }
`

const Container = styled.div`
  max-width: 794px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 40px 16px;

  h2 {
    color: ${(props) => props.theme.colors.red};
    font-size: 34px;
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  h3 {
    color: ${(props) => props.theme.colors.red};
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 17px;
    letter-spacing: -0.24px;
    margin-top: 0;
    line-height: 1.4;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .CoverageCollapse__ {
    margin: 10px 0;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 50px 100px;
  }

  @media (${breakpoints.desktop.min}) {
    padding: 50px 100px;
  }
`

const Map = styled.iframe`
  width: 100%;
  min-height: 300px;
  padding: 10px;
  border: none;
  @media (${breakpoints.desktop.min}) {
    min-height: 332px;
  }
`

const Coverage = ({
  data: {
    allContentfulPgPage: { edges },
  },
}: PgPageProps) => {
  const { description: descriptionMeta, keywords, title: pageTitle, template } = edges[0].node
  const { title, preTitle, description, collapsibleContent } = template

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Cobertura' })
  }, [])

  return (
    <LayoutStaticPage description={descriptionMeta} keywords={keywords} title={pageTitle}>
      <Wrapper>
        <Container>
          <h4>{preTitle}</h4>
          <h2>{title}</h2>
          <p>{description}</p>
          {collapsibleContent.length === 0 ? (
            <Loading />
          ) : (
            collapsibleContent.map((collapse: CollapsibleContent) => {
              const htmlContent = collapse?.customHtml?.customHtml
              if (collapse.mapId) {
                return (
                  <Collapse className="CoverageCollapse__" label={collapse.title} key={collapse.id}>
                    <Map src={`${secrets.GATSBY_GOOGLE_MAPS_URL_EMBED}${collapse.mapId}`} />
                  </Collapse>
                )
              }
              if (collapse.textItems && !collapse.mapId) {
                return (
                  <Collapse className="CovidCollapse__" label={collapse.title} key={collapse.id}>
                    <ul>
                      {collapse.textItems.map((textItem: string) => (
                        <li>
                          <p>{textItem}</p>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                )
              }
              if (collapse.customHtml && !collapse.mapId && !collapse.textItems) {
                return (
                  <Collapse className="FaqsCollapse__" label={collapse.title} key={collapse.id}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  </Collapse>
                )
              }
              return null
            })
          )}
        </Container>
      </Wrapper>
    </LayoutStaticPage>
  )
}

export default withPageTransition(Coverage)

export const query = graphql`
  query CoverageQuery($coverageId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $coverageId } }) {
      edges {
        node {
          id
          title
          description
          keywords
          template {
            ... on ContentfulTmStaticPage {
              id
              name
              title
              preTitle
              description
              collapsibleContent {
                id
                title
                mapId
                textItems
                customHtml {
                  customHtml
                }
              }
            }
          }
        }
      }
    }
  }
`
